import { useEffect, useState } from 'react';
import Icon from '../../../atoms/Icon/Icon';
import InlineTextarea from '../../../atoms/InlineEditing/InlineTextarea/InlineTextarea';
import NotVisibleOverlay from '../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay';

export default function ReportTemplateTextBlock({
  props,
  placeholder: originalPlaceholder,
  getPlaceholderWithProps,
  children,
  setRequiredAndInvalid,
  showErrors,
  onEndEditing = () => { },
  showOptions = null,
  fetus: unFormattedFetus,
  reportMode = "edit",
  canEdit = false,
  highlight = false,
  BIContext: InitialBIContext
}) {
  const [editing, setEditing] = useState(false);
  let placeholder, fetus;

  if (props.data?.startsWith("measurement.")) {
    fetus = (getPlaceholderWithProps({ data: "fetus.number" })?.value || [])[Number(props.fetus ?? fetus ?? 1)] ?? -1;
  } else {
    fetus = Number(props.fetus ?? unFormattedFetus ?? 1);
  }
  if (Array.isArray(originalPlaceholder?.value) || props.data?.startsWith("measurement.")) {
    placeholder = { ...originalPlaceholder, value: originalPlaceholder?.value?.[fetus] };
  } else {
    placeholder = { ...originalPlaceholder, value: [originalPlaceholder?.value, originalPlaceholder?.value] };
  }

  const value = Array.isArray(placeholder?.value)
    ? placeholder?.value[fetus] ?? children
    : placeholder?.value ?? children;
  const visible = highlight ? true : (placeholder?.visible ?? true);

  showOptions = showOptions ?? placeholder?.showOptions ?? true;

  const saveChange = (updates) => {
    setTimeout(() => setEditing(false), 200);
    if (!props.data) return false;
    const id = props.data;
    const custom = props.custom === "true";

    if (updates.value) {
      if (fetus && Array.isArray(originalPlaceholder.value)) {
        let value = originalPlaceholder.value;
        value[fetus] = updates.value;
        updates.value = value;
      }
    }

    if ((props.data?.startsWith('custom.') || props.custom === "true") && (updates.value || originalPlaceholder.value) && typeof updates.value !== 'object') {
      let newUpdatesValue = (Array.isArray(originalPlaceholder.value) ? (originalPlaceholder.value || "") : [originalPlaceholder.value || "", originalPlaceholder.value || ""]);
      newUpdatesValue[fetus] = updates.value;
      updates.value = newUpdatesValue;
    }

    const BIContext = { ...InitialBIContext, component: 'text-block' };
    onEndEditing(id, updates, custom, { BIContext });
  };

  const invalidValue = () => {
    return !value;
  };

  useEffect(() => {
    if (props.required === "true" && setRequiredAndInvalid) {
      setRequiredAndInvalid((prevState) => {
        invalidValue() ?
          prevState.add(props.data) : prevState.delete(props.data);
        return prevState;
      });
    }
  }, [value, setRequiredAndInvalid]);

  const isRequired = () => {
    return props.required === "true" ? <span className="required">*</span> : false;
  };

  let labelStyle = {};
  if (props["label-width"]) {
    labelStyle.width = props["label-width"];
    labelStyle.minWidth = props["label-width"];
  }

  const getValue = () => {
    if (!value) return "";
    return `${Array.isArray(value) ? (value[fetus] ?? "") : value}`.trim().replace("\r", "");
  }

  const printableVersion = () => {
    // this has been done to keep the first sentence attached with the previous title / label when printing on different pages
    const content = getValue();
    const firstSentence = extractFirstSentence(content);
    const rest = content.substr(firstSentence.length).trim();
    return <>
      {!!firstSentence && <div className={getClassName()} dangerouslySetInnerHTML={{ __html: firstSentence.replace("\n", "<br />") }} />}
      {!!rest && <div className={getClassName()} dangerouslySetInnerHTML={{ __html: rest.replace("\n", "<br />") }} />}
    </>;
  }

  const extractFirstSentence = (content) => {
    let separator = "";
    let separatorIndex = 0;

    for (const s of [".\n", "\n", ". "]) {
      separator = s;
      separatorIndex = content.indexOf(s);
      if (separatorIndex > 0) break;
    }

    if (separatorIndex <= 0) return content;
    return content.substr(0, separatorIndex + separator.length);
  }

  const getClassName = () => {
    return `
      textblock-wrapper exam-report-editing-field
      ${visible ? 'is-visible' : 'not-visible'}
      ${getValue() ? (visible ? 'has-printable-value' : '') : 'not-printable'}
      ${props.required === "true" && invalidValue() && showErrors ? 'required-error' : ''}
      ${!!props.label ? 'is-block' : 'is-inline'}
      ${!!props.data ? 'is-editable' : ''}
      ${!!editing ? 'is-editing' : ''}
    `;
  }

  return reportMode === "edit" ? (
    <div className={getClassName()}>
      {!!props.label && (<div className="label" style={labelStyle}>{props.label} {isRequired()}</div>)}
      <span className={`${highlight ? 'highlight-field' : ''}`}>
        <div className="textblock-text exam-report-editing-value" data-editing={editing === "value"}>
          {props.required === "true" && (<span className="required">*</span>)}
          <InlineTextarea value={getValue()} printable={visible} active={canEdit} onStartEditing={() => setEditing("value")} onChange={value => saveChange({ value })} />
          {!props.label && isRequired()}
        </div>
        {highlight && highlight.icon && (
          <span className={`highlight-field_icon ${highlight.iconClass}` || ''}><Icon name={highlight.icon} /></span>
        )}
        {!highlight && visible && canEdit && reportMode === "edit" && showOptions && (
          <div className="content-options exam-report-editing-options">
            <div onClick={() => saveChange({ visible: !visible })}>
              <Icon name={visible ? "eye" : "eye-off"} />
            </div>
          </div>
        )}
      </span >
      {!visible && reportMode === "edit" && canEdit && <NotVisibleOverlay onClick={() => saveChange({ visible: !visible })} />}
    </div >
  ) : (
    printableVersion()
  );
}
