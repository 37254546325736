import { SHAPES_AND_COLOURS } from "../../BiometryGraph/Constants";

export default function ReportTemplateMeasurementCurveLegend({ t: __, getPlaceholderWithProps }) {
  const names = getPlaceholderWithProps({ data: "fetus.name" })?.value?.slice(1) || [];

  if (names.length < 2) return false;

  return (
    <div className="measurement-curve-legend" >
      {names.map((fetusName, idx) => {

        const { path, colour } = SHAPES_AND_COLOURS[idx] || SHAPES_AND_COLOURS[0];
        return (
          <div className="measurement-curve-legend-item" key={idx}>
            <div>Fetus {fetusName}</div>
            <div><CurveShape path={path} colour={colour} /></div>
          </div>
        );
      })}
    </div>
  );
}

const CurveShape = ({ path, colour }) => {
  return (<svg viewBox="-2 -2 4 4">
    <g className="measurements" >
      <g className="point" >
        <path d={path} style={{ fill: colour }} />
      </g>
    </g>
  </svg>);
};
